.boarding {
  min-height: 550px;
  width: 100%;
  background: url(./static/bg2.svg);
  background-size: cover;
  background-position: center;

  padding: 0px;
  margin: 0px;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__first {
    padding-left: 30px;
  }
}

.aboutUs {
  width: 100%;
  background-color: gray;
  min-height: 600px;
}

.whatWeDo {
  min-height: 550px;
  width: 100%;
  background: url(./static/maxresdefault2.png);
  background-size: cover;
  background-position: center;

  .body {
    width: 100%;
    min-height: 550px;
    padding-top: 50px;
    margin: 0px;
    background-color: black;
    opacity: 0.6;
  }
}

.customer {
  min-height: 550px;
  width: 100%;
  background: black;
  padding-top: 50px;
}

.footer {
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 59%, rgba(0,212,255,1) 100%);
  height: 400px;
  width: 100%;
  border-radius: 0px 0px 250px 0px;
  padding: 30px;
}
